import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Task as GanttTask } from 'gantt-task-react'
import { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ProjectStatusIcon } from 'pages/dashboard/components/projectsCardView/components/projectStatus/ProjectStatusIcon'
import { ProjectsTimelineContext } from 'pages/dashboard/components/projectsTimelineView/ProjectsTimelineProvider'
import styles from 'pages/project/components/timeline/components/taskListTable/TaskListTable.module.scss'
import { highlightRow, unHighlightRow } from 'pages/project/components/timeline/components/taskListTable/utils'
import { ProjectTimelinePhase } from 'types/projects/projectsTimeline'
import { routesManager } from 'utils/routesManager'

interface Props {
  rowHeight: number
  rowWidth: string
  fontFamily: string
  fontSize: string
  locale: string
  tasks: GanttTask[]
}

export const ProjectsListTable = ({ rowHeight, rowWidth, tasks: ganttTasks, fontFamily, fontSize }: Props) => {
  const { timeline } = useContext(ProjectsTimelineContext)
  const navigate = useNavigate()

  const timelineTasks = useMemo(() => {
    const allProjects = Object.fromEntries(timeline.map(task => [task.id, task]))

    return allProjects
  }, [timeline])

  const handleRedirect = (project: GanttTask) => {
    navigate(routesManager.project.timeline.getURL({ id: project.id }))
  }

  const combinePhaseNames = useCallback((phases: ProjectTimelinePhase[]) => {
    return phases.map(phase => phase.name).join(', ')
  }, [])

  return (
    <div
      className={styles.taskListWrapper}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {ganttTasks.map((task, idx) => {
        const timelineTask = timelineTasks[task.id]

        if (!timelineTask) {
          return null
        }

        const node = (
          <div
            className={clsx(styles.cursorPointer, styles.taskListCell)}
            style={{
              minWidth: rowWidth,
              maxWidth: rowWidth,
            }}
            title={timelineTask.name}
            onClick={() => handleRedirect(task)}
          >
            <Flex gap={8} align="center" className={styles.widthWrapper}>
              <ProjectStatusIcon status={timelineTask!.status} />
              <Flex direction="column" className={styles.widthWrapper}>
                <div>
                  <WppTypography
                    type="s-body"
                    className={clsx(styles.ellipsis, styles.name)}
                    onClick={() => handleRedirect(task)}
                  >
                    {timelineTask.name}
                  </WppTypography>
                </div>

                {!!timelineTask.phases.length && (
                  <WppTypography className={clsx(styles.ellipsis, styles.grey800)} type="xs-body">
                    {combinePhaseNames(timelineTask.phases)}
                  </WppTypography>
                )}
              </Flex>
            </Flex>
          </div>
        )

        return (
          <div
            className={clsx(styles.taskListTableRow)}
            style={{ height: rowHeight }}
            key={`${task.id}row`}
            onMouseEnter={() => highlightRow(idx)}
            onMouseLeave={() => unHighlightRow(idx)}
          >
            {node}
          </div>
        )
      })}
    </div>
  )
}

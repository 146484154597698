// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gF3dC{height:100%}.VkPks{display:grid;grid-gap:20px;grid-row:auto;grid-template-columns:1fr 1fr}.r66Ld{--input-select-dropdown-max-height: 300px}.lX1n8{display:grid;grid-template-columns:repeat(4, 1fr);grid-gap:16px;gap:16px}.KNdfX{width:32px;height:32px;border-radius:7px}.u5wEd{grid-column-start:1}.u5wEd::part(datepicker-container){width:100%}.f6gpQ{color:var(--wpp-grey-color-800)}.KPzMH{padding-bottom:48px}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/projectForm/ProjectForm.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,YAAA,CACA,aAAA,CACA,aAAA,CACA,6BAAA,CAGF,OACE,yCAAA,CAGF,OACE,YAAA,CACA,oCAAA,CACA,aAAA,CAAA,QAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,OACE,mBAAA,CAEA,mCACE,UAAA,CAIJ,OACE,+BAAA,CAGF,OACE,mBAAA","sourcesContent":[".spinner {\n  height: 100%;\n}\n\n.grid {\n  display: grid;\n  grid-gap: 20px;\n  grid-row: auto;\n  grid-template-columns: 1fr 1fr;\n}\n\n.hierarchySelect {\n  --input-select-dropdown-max-height: 300px;\n}\n\n.radioGroup {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 16px;\n}\n\n.cardIcon {\n  width: 32px;\n  height: 32px;\n  border-radius: 7px;\n}\n\n.datePicker {\n  grid-column-start: 1;\n\n  &::part(datepicker-container) {\n    width: 100%;\n  }\n}\n\n.greyColor800 {\n  color: var(--wpp-grey-color-800);\n}\n\n.paddingBottom {\n  padding-bottom: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `gF3dC`,
	"grid": `VkPks`,
	"hierarchySelect": `r66Ld`,
	"radioGroup": `lX1n8`,
	"cardIcon": `KNdfX`,
	"datePicker": `u5wEd`,
	"greyColor800": `f6gpQ`,
	"paddingBottom": `KPzMH`
};
export default ___CSS_LOADER_EXPORT___;

import { WppActionButton, WppSkeleton, WppTag } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetMiroMe } from 'api/miro/queries/useGetMiroMe'
import { Flex } from 'components/common/flex/Flex'
import { SvgMiroLogo } from 'components/svg/SvgMiroLogo'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { showConnectMiroToUserProfileModal } from 'pages/components/projectModal/components/integrations/miro/connectMiroToUserProfile/ConnectMiroToUserProfileModal'
import { queryClient } from 'providers/osQueryClient/utils'

export const MiroConnect = () => {
  const { t } = useTranslation()
  const {
    osContext: { userDetails },
  } = useOs()
  const { status, isFetching } = useGetMiroMe()

  useEffect(() => {
    const childResponse = (e: any) => {
      // Only handle messages from Miro's origin
      if (e.origin === window.location.origin && e?.data?.miro) {
        queryClient.invalidateQueries([ApiQueryKeys.MIRO_ME])
      }
    }

    // Add event listener to receive messages from child window (Miro OAuth)
    window.addEventListener('message', childResponse)

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('message', childResponse)
  }, [])

  if (isFetching) {
    return <WppSkeleton variant="rectangle" height="32px" width="100%" />
  }

  return (
    <>
      <Flex justify="between" className="wpp-spacing-4-bottom">
        <Flex align="center" data-testid="miro-connect-row">
          <SvgMiroLogo className="wpp-spacing-8-right" data-testid="miro-logo" /> {t('integrations.miro.title')}
        </Flex>

        {status === 'success' ? (
          <WppTag variant="positive" label={t('integrations.connected')} data-testid="miro-connected-tag" />
        ) : (
          <WppActionButton
            data-testid="miro-connect"
            onClick={() => showConnectMiroToUserProfileModal({ email: userDetails?.email })}
          >
            {t('integrations.connect')}
          </WppActionButton>
        )}
      </Flex>
    </>
  )
}

import { isDevelop } from 'utils/common'
import { getDevEnv } from 'utils/dev'

export enum API_TYPES {
  WPP_PROJECTS_API = 'WPP_PROJECTS_API',
  WPP_PROJECT_WSS_API = 'WPP_PROJECT_WSS_API',
  WPP_MASTER_DATA_API = 'WPP_MASTER_DATA_API',
  WPP_COLLIDER_API = 'WPP_COLLIDER_API', // 3rd-party integrations, e.g. wrike
}

const env = getDevEnv() || 'hulk'
const developmentApi: Record<API_TYPES, string> = {
  WPP_PROJECTS_API: `https://project-api-ch-${env}.os-dev.io/api`,
  WPP_PROJECT_WSS_API: `wss://project-api-ch-${env}.os-dev.io/api/ws/events`,
  WPP_MASTER_DATA_API: `https://master-data-api-ch-${env}.os-dev.io/api`,
  WPP_COLLIDER_API: `https://collider-api-ch-${env}.os-dev.io/api`,
}

const productionApi: Record<API_TYPES, string> = {
  WPP_PROJECTS_API: 'https://project-api-ch-loki.os-dev.io/api',
  WPP_PROJECT_WSS_API: 'wss://project-api-ch-loki.os-dev.io/api/ws/events',
  WPP_MASTER_DATA_API: 'https://master-data-api-ch-loki.os-dev.io/api',
  WPP_COLLIDER_API: 'https://collider-api-ch-loki.os-dev.io/api',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api = !isDevelop
    ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
    : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P1HDK{width:100%}.P1HDK::part(card){width:100%}.j9fDn{display:grid;grid-template-columns:repeat(3, minmax(0, 1fr));grid-gap:25px;gap:25px}.cf8Up{width:100%}.cf8Up::part(card){width:100%;max-width:100%}.OAvG3{height:44px}.w9JGI{margin-top:4px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/projectDashboard/coponents/workflowWidget/WorkflowWidget.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAEA,mBACE,UAAA,CAIJ,OACE,YAAA,CACA,+CAAA,CACA,aAAA,CAAA,QAAA,CAGF,OACE,UAAA,CAEA,mBACE,UAAA,CACA,cAAA,CAIJ,OACE,WAAA,CAGF,OACE,cAAA","sourcesContent":[".card {\n  width: 100%;\n\n  &::part(card) {\n    width: 100%;\n  }\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n  gap: 25px;\n}\n\n.subCard {\n  width: 100%;\n\n  &::part(card) {\n    width: 100%;\n    max-width: 100%;\n  }\n}\n\n.placeholder {\n  height: 44px;\n}\n\n.workflowWidgetWrapper {\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `P1HDK`,
	"grid": `j9fDn`,
	"subCard": `cf8Up`,
	"placeholder": `OAvG3`,
	"workflowWidgetWrapper": `w9JGI`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XdfGp{background-color:var(--wpp-grey-color-300);border-radius:var(--wpp-border-radius-s);inset:0}.kY7mG{position:relative}.kY7mG::before{display:block;width:100%;margin-bottom:12px;background-color:var(--wpp-grey-color-300);border-radius:var(--wpp-border-radius-s);content:"";height:100px;height:var(--placeholder-height, 100px)}.HdN8b{position:relative}.HdN8b::after{display:block;width:100%;margin-bottom:12px;background-color:var(--wpp-grey-color-300);border-radius:var(--wpp-border-radius-s);content:"";height:100px;height:var(--placeholder-height, 100px);margin-top:12px}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/placeholder/Placeholder.module.scss"],"names":[],"mappings":"AAAA,OACE,0CAAA,CACA,wCAAA,CACA,OAAA,CAaF,OACE,iBAAA,CAEA,eAZA,aAAA,CACA,UAAA,CACA,kBAAA,CACA,0CAAA,CACA,wCAAA,CACA,UAAA,CAUE,YAAA,CAAA,uCAAA,CAIJ,OACE,iBAAA,CAEA,cAtBA,aAAA,CACA,UAAA,CACA,kBAAA,CACA,0CAAA,CACA,wCAAA,CACA,UAAA,CAoBE,YAAA,CAAA,uCAAA,CACA,eAAA","sourcesContent":[".placeholder {\n  background-color: var(--wpp-grey-color-300);\n  border-radius: var(--wpp-border-radius-s);\n  inset: 0;\n}\n\n@mixin dndPlaceholderPseudo {\n  display: block;\n  width: 100%;\n  margin-bottom: 12px;\n  background-color: var(--wpp-grey-color-300);\n  border-radius: var(--wpp-border-radius-s);\n  content: '';\n}\n\n// placeholders, that appears above/below cards, when user is trying to drop element \"on card\"\n.dropPlaceholderTop {\n  position: relative;\n\n  &::before {\n    @include dndPlaceholderPseudo;\n\n    height: var(--placeholder-height, 100px);\n  }\n}\n\n.dropPlaceholderBottom {\n  position: relative;\n\n  &::after {\n    @include dndPlaceholderPseudo;\n\n    height: var(--placeholder-height, 100px);\n    margin-top: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `XdfGp`,
	"dropPlaceholderTop": `kY7mG`,
	"dropPlaceholderBottom": `HdN8b`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frfHM{font-weight:600;font-weight:var(--wpp-typography-s-strong-font-weight, 600)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/members/components/removeMembersModal/RemoveMembersModal.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAAA,2DAAA","sourcesContent":[".boldText {\n  font-weight: var(--wpp-typography-s-strong-font-weight, 600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boldText": `frfHM`
};
export default ___CSS_LOADER_EXPORT___;
